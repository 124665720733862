import { createApi } from '@reduxjs/toolkit/query/react';
import {
  ParkingSession,
  ParkingSessionApiResponse,
} from 'models/ParkingSessions';
import { baseQuery } from '..';

export const ParkingSessionsApi = createApi({
  reducerPath: 'parkingSessionsApi',
  baseQuery,
  keepUnusedDataFor: 0.5,
  tagTypes: ['parking-sessions', 'parking-session'],
  endpoints: (builder) => ({
    getParkingSessions: builder.query<ParkingSessionApiResponse, string>({
      query: (url) => ({
        url: url,
        keepUnusedDataFor: 0.5,
      }),
      providesTags: ['parking-sessions'],
    }),
    getParkingSession: builder.query<ParkingSession, string>({
      query: (url) => ({
        url: url,
      }),
      providesTags: ['parking-session'],
    }),
  }),
});

export const { useGetParkingSessionsQuery, useGetParkingSessionQuery } =
  ParkingSessionsApi;
